<template>
  <div>
    <h1>{{ title }}</h1>

    <viewsource-link to="/" :types="['underlined']"
      >Back to the homepage</viewsource-link
    >
  </div>
</template>

<script>
import ViewsourceLink from '~/components/ViewsourceLink.vue';
import { SITE_TITLE } from '~/assets/js/constants';

export default {
  components: {
    ViewsourceLink,
  },

  props: {
    error: {
      type: Object,

      required: true,
    },
  },

  computed: {
    title() {
      const { statusCode } = this.error;

      if (statusCode === 404) {
        return 'Page Not Found';
      }

      return 'An Error Occurred';
    },
  },

  head() {
    return {
      title: `${this.title} | ${SITE_TITLE}`,
    };
  },
};
</script>
