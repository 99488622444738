<template>
  <a v-if="href" :href="href" :class="className">
    <slot />
  </a>

  <nuxt-link v-else-if="to" :to="to" :class="className">
    <slot />
  </nuxt-link>

  <button v-else :class="className">
    <slot />
  </button>
</template>

<script>
const UNDERLINED = 'underlined';
const TYPES = [UNDERLINED];

export default {
  props: {
    href: {
      type: String,

      default: '',
    },

    to: {
      type: [String, Object],

      default: '',
    },

    types: {
      type: Array,

      validator: types => types.every(type => TYPES.includes(type)),

      default: () => [],
    },
  },

  computed: {
    className() {
      return [
        'c-viewsource-link',
        {
          'c-viewsource-link--underlined': this.types.includes(UNDERLINED),
        },
      ];
    },
  },
};
</script>

<style lang="postcss">
.c-viewsource-link {
  color: var(--color-internet-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid var(--color-gold);
    outline-offset: var(--spacing-1);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @media (prefers-color-scheme: dark) {
    color: var(--color-anzac);
  }

  @nest .t-light & {
    color: var(--color-internet-blue);
  }

  @nest .t-dark & {
    color: var(--color-anzac);
  }
}

.c-viewsource-link--underlined {
  text-decoration: underline;

  @supports (text-decoration-thickness: 4px) {
    text-decoration-thickness: 4px;
    text-decoration-color: var(--color-white-lilac);
    /* NOTE: for safari */
    text-decoration-skip: none;
    text-decoration-skip-ink: none;
    text-underline-position: under;
    text-underline-offset: -4px;

    @media (prefers-color-scheme: dark) {
      text-decoration-color: var(--color-ship-gray);
    }

    @nest .t-light & {
      text-decoration-color: var(--color-white-lilac);
    }

    @nest .t-dark & {
      text-decoration-color: var(--color-ship-gray);
    }
  }

  &:hover {
    @supports (text-decoration-thickness: 4px) {
      text-decoration-thickness: 4px;
    }

    text-decoration-color: var(--color-gold);

    @media (prefers-color-scheme: dark) {
      text-decoration-color: var(--color-mid-gray);
    }

    @nest .t-light & {
      text-decoration-color: var(--color-gold);
    }

    @nest .t-dark & {
      text-decoration-color: var(--color-mid-gray);
    }
  }
}
</style>
