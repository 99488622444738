<template>
  <div class="l-default">
    <lazy-hydrate when-idle>
      <site-header class="l-default__site-header" />
    </lazy-hydrate>

    <main class="l-default__main">
      <nuxt />
    </main>

    <fathom-affiliate-banner
      v-if="isBlogPost"
      class="l-default__fathom-affiliate-banner"
    />

    <lazy-hydrate ssr-only>
      <site-footer class="l-default__site-footer" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

import supportsRequestAnimationFrame from '~/assets/js/supports/request-animation-frame';

const FathomAffiliateBanner = () =>
  import('~/components/FathomAffiliateBanner.vue');
const SiteHeader = () => import('~/components/SiteHeader.vue');
const SiteFooter = () => import('~/components/SiteFooter.vue');

export default {
  components: {
    LazyHydrate,
    FathomAffiliateBanner,
    SiteHeader,
    SiteFooter,
  },

  computed: {
    isBlogPost() {
      return this.$route.name === 'slug';
    },
  },

  mounted() {
    if (supportsRequestAnimationFrame) {
      requestAnimationFrame(() => {
        document.documentElement.classList.add('is-hydrated');
      });

      return;
    }

    document.documentElement.classList.add('is-hydrated');
  },
};
</script>

<style lang="postcss">
:root {
  --column-count: 24;
}

.l-default {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 50rem;
  min-height: 100vh;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));
  }

  & > * {
    width: 100%;
    max-width: calc(22 / var(--column-count) * 100%);

    @supports (display: grid) {
      grid-column: 2 / -2;
      max-width: 100%;
    }
  }
}

.l-default__site-header {
  margin: var(--spacing-6) auto;

  @media (--gamma) {
    margin: var(--spacing-8) auto;
  }
}

.l-default__main {
  margin: var(--spacing-6) auto var(--spacing-10);
}

.l-default__fathom-affiliate-banner {
  margin: var(--spacing-7) auto;

  @media (--gamma) {
    box-sizing: content-box;
    margin-left: calc(-1 * var(--spacing-4));
    margin-right: calc(-1 * var(--spacing-4));
  }
}

.l-default__site-footer {
  margin: var(--spacing-6) auto;
}
</style>
