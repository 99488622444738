export const UPDATE_QUERY = 'update-query';

const initialState = {
  query: '',
  hits: [],
  requestCount: 0,
  isLoading: false,
};

export const state = () => ({ ...initialState });

export const mutations = {
  updateQuery(state, query) {
    state.query = query;
  },

  setHits(state, hits) {
    state.hits = hits;
  },

  startSearch(state) {
    state.requestCount = state.requestCount + 1;

    state.isLoading = true;
  },

  endSearch(state) {
    state.requestCount = state.requestCount - 1;

    if (state.requestCount === 0) {
      state.isLoading = false;
    }
  },
};

export const actions = {
  async search({ commit, state }) {
    const requestQuery = state.query.trim();

    if (requestQuery.length === 0) {
      commit('setHits', []);

      return;
    }

    const url = `${process.env.SEARCH_API_URL}?q=${requestQuery}`;

    commit('startSearch');

    try {
      const response = await fetch(url, {
        headers: {
          'X-Meili-API-Key': process.env.SEARCH_API_KEY,
        },
      });

      commit('endSearch');

      if (response.ok && response.status === 200) {
        const { hits, query: responseQuery } = await response.json();

        if (responseQuery === requestQuery) {
          commit('setHits', hits);
        }

        return;
      }

      const { message } = await response.json();
      const { status, statusText } = response;

      throw new Error(
        `Search API query failed (${status} ${statusText}): '${message}'`,
      );
    } catch (error) {
      commit('endSearch');

      // if (process.env.NODE_ENV === 'production') {
      //   const { sentry } = window;

      //   sentry.captureException(error);
      // }

      console.error(error);
    }
  },
};
