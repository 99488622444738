import { sortByDate as byDate, mergeById } from '~/assets/js/utilities';

// mutations
export const ADD_ARTICLE = 'add-article';

// actions
export const FETCH_INDEX = 'fetch-index';
export const FETCH_ARTICLE = 'fetch-article';
export const ADD_ARTICLES = 'add-articles';
export const SET_INDEX = 'set-index';

const initialState = {
  articles: [],
};

export const state = () => ({ ...initialState });

export const mutations = {
  [ADD_ARTICLE]: (state, article) => {
    const existing = state.articles.find(({ slug }) => article.slug === slug);

    if (existing == null) {
      state.articles = [...state.articles, article];

      return;
    }

    const index = state.articles.findIndex(({ slug }) => article.slug === slug);

    state.articles = [
      ...state.articles.slice(0, index),
      {
        ...existing,
        ...article,
      },
      ...state.articles.slice(index + 1),
    ];
  },
};

export const getters = {
  articles: ({ articles }) =>
    [...articles]
      .filter(
        ({ index = false }) =>
          !(process.env.SITE_ENV === 'production') || index,
      )
      .sort(byDate),
  articleBySlug: ({ articles }) => slug =>
    articles.find(article => article.slug === slug),
};

export const actions = {
  async [FETCH_INDEX]({ dispatch }) {
    const { articles } = await import('~/assets/data/index.json');

    dispatch(ADD_ARTICLES, articles);
  },

  async [FETCH_ARTICLE]({ dispatch }, slug) {
    const article = await import(`~/assets/data/articles/${slug}.json`);

    dispatch(ADD_ARTICLES, [article]);
  },

  [ADD_ARTICLES]({ commit }, articles) {
    articles.forEach(article => commit(ADD_ARTICLE, article));
  },

  [SET_INDEX]({ dispatch }, { articles }) {
    dispatch(ADD_ARTICLES, articles);
  },

  async nuxtServerInit({ dispatch }) {
    const index = require('~/assets/data/articles/index.json');
    const syndication = require('~/syndication.json');

    const articles = mergeById([
      ...index,
      ...syndication,
    ]).map(({ devUrl, slug }) => ({ devUrl, slug }));

    dispatch(ADD_ARTICLES, articles);
  },
};
