export const isValidHostname = (() => {
  if (process.server) {
    return;
  }

  const { hostname } = window.location;

  return (
    hostname === 'viewsource.io' ||
    hostname.includes('--viewsource.netlify.com')
  );
})();

export const sortByDate = (a, b) => {
  if (typeof a.date === 'undefined' || typeof b.date === 'undefined') {
    return 0;
  }

  return new Date(b.date) - new Date(a.date);
};

export const mergeById = items =>
  items.reduce(
    (acc, obj) =>
      typeof acc.find(({ id }) => obj.id === id) !== 'undefined'
        ? acc
        : [
            ...acc,
            Object.assign({}, obj, ...items.filter(({ id }) => obj.id === id)),
          ],
    [],
  );

export const removeHtmlClassName = className => {
  if (process.server) {
    return;
  }

  const htmlElement = document.documentElement;
  const classNames = htmlElement.className
    .split(' ')
    .filter(htmlClassName => htmlClassName !== className)
    .filter(Boolean);

  htmlElement.className = classNames.join(' ');
};

export const addHtmlClassName = className => {
  if (process.server) {
    return;
  }

  if (document.documentElement.className.length > 0) {
    document.documentElement.className += ` ${className}`;

    return;
  }

  document.documentElement.className = className;
};
